import { extendTheme } from "@chakra-ui/react";
const theme = {
  styles: {
    global: {
      "img.react-images__view-image": {
        margin: "auto",
      },
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "Courgette, serif, sans-serif",
      },
    },
  },
  colors: {
    primary: "rebeccapurple",
  },
};

export default extendTheme(theme);
